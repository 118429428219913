import {CardActions, FormHelperText, IconButton} from "@barracuda-internal/bds-core";
import {Grid, GridColumn, GridNoRecords} from "@progress/kendo-react-grid";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import {get, isArray, isString, merge} from "lodash";
import React from "react";
import DeleteDialog, {DeleteDialogProps} from "../../dialog/DeleteDialog/DeleteDialog";
import ActionButtonsField from "../../fields/ActionButtonsField/ActionButtonsField";
import TableActionsAndFilters from "../../table/TableActionsAndFilters/TableActionsAndFilters";
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import TableInputAddEditDialog, {TableInputAddEditDialogProps} from "./TableInputAddEditDialog";
import {makeOverrideableStyles, StyledComponentProps} from "@cuda-react/theme";
import {Theme} from "@mui/material";
import {createStyles} from "@mui/styles";
import {CrudParams} from "../../../clients";
import {ClonableChildren} from "../../../utils/commonTypes";
import {TableInputAddEditFormProps} from "./TableInputAddEditForm";

export interface BaseEditableTableProps {
    /**
     * extra props passed to the 'Add' button
     */
    addButtonProps?: Partial<TableInputAddEditDialogProps>,
    /**
     * if true add button is rendered as "+" icon
     */
    addButtonIcon?: boolean,
    /**
     * the children to render within [TableInput](/?path=/docs/core-components-inputs-tableinput--table-input).
     * Each child will render a column in the Grid to display it's information.
     *
     * The *Field components in cuda-react are all made to seamlessly work with ConnectedTable.
     *
     * Additionally, if formChildren is not provided, the inputs for the Add/Edit form will automatically be generated using props set on the children.
     * The following props can be provided on each child:
     *
     * @param {string} child.props.source used by *Field components to define the field within the row data that should be used when rendering content.
     * @param {string} child.props.label the label to use in the column header.
     * @param {element} child.props.inputComponent the input component to use. The source/label is copied across from the field.
     * @param {object} child.props.inputProps additional props to pass to the generated form input component.
     * @param {string} child.props.cellClassName classname passed to the rendered td/th cells.
     * @param {number} child.props.width static column width, in pixels. All columns that do not have a defined width will shrink/expand evenly to fill the space.
     * @param {boolean} child.props.hidden sets whenever to show/hide the generated form input component.
     * @param {object} child.props.columnProps additional props to provide to the rendered GridColumn component.
     */
    // TODO: Just remove the "create form from children" and make it formChildren only.
    children?: ClonableChildren,
    /**
     * default values for the Add/Edit form.
     */
    defaultFormValues?: TableInputAddEditDialogProps["data"],
    /**
     * extra props passed to the delete dialog component rendered inside the Add/Edit form.
     */
    deleteButtonProps?: Partial<DeleteDialogProps>,
    /**
     * if true, the prop "disabled" is passed to all rendered children.
     */
    disabled?: boolean,
    /**
     * extra props passed to the 'Edit' button.
     */
    editButtonProps?: Partial<TableInputAddEditDialogProps>,
    /**
     * specific form for the Add/Edit form. If provided, the component will not try to create the Add/Edit form out of it's childrens.
     * It will be passed to a [Form](/?path=/docs/core-components-forms-form--form) instance
     */
    formChildren?: React.ReactElement | (React.ReactElement | null)[] | null,
    /**
     * function to validate all the inputs of the Add/Edit form.
     */
    formValidate?: TableInputAddEditDialogProps["formValidate"],
    /**
     * function to format the error object as it is passed in from the form.
     * @function
     * @param {string} meta.error message to display
     * @param {object} inputValue current input value.
     * @return {array|string} error message(s) to display.
     */
    formatError?: (error: any, inputValue: any) => string | any[],
    /**
     * function to format the data when the Add/Edit form is saved, prior to adding to the array value.
     */
    formatFormData?: TableInputAddEditDialogProps["formatFormData"],
    /**
     * function to format the way the data is read.
     * @param {node} inputValue current input value.
     */
    formatInput?: (inputValue: any) => any,
    /**
     * function to format the way the data is set.
     * @param {node} newData current input value.
     * @param {node} inputValue current input value.
     */
    formatOutput?: (newData: any, inputValue: any) => any,
    /**
     * id used for the main div wrapper of this component.
     */
    id: string,
    /**
     * object containing all the labels for the diferent elements of the component.
     * @property {string}  addButtonText Label for the add button in the Add/Edit Form.
     * @property {string} addTitle Header for the Add form
     * @property {string|node} deleteMessage Message to display on the DeleteDialog component.
     * @property {string} deleteTitle Header for the DeleteDialog component
     * @property {string} editTitle Header for the Edit form
     * @property {string} noDataMessage Message to display when no data has been provided to the Grid component
     */
    labels?: {
        addButtonText?: string,
        addTitle?: string,
        deleteMessage?: React.ReactNode,
        deleteTitle?: string,
        editTitle?: string,
        noDataMessage?: string
    },
    /**
     * error associated with this input.
     */
    error?: string | object | any[],
    /**
     * min width for each of the cells of the Grid if the width is not specified on the child.
     */
    minCellWidth?: number,
    /**
     * Minimum number of items that need to exist.
     * Used in the Grid to disabled the Delete button in case the number of items is lower or equal to this prop.
     */
    minimumItems?: number,
    /**
     * If true, Add button will not get rendered.
     */
    noCreate?: boolean,
    /**
     * If true, Remove buttons will not get rendered on each row of the Grid.
     */
    noDelete?: boolean,
    /**
     * If true, Edit buttons will not get rendered on each row of the Grid.
     */
    noEdit?: boolean,
    /**
     * callback to called when component stops being interacted with.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onBlur
     */
    onBlur?: (newValue: any) => void,
    /**
     * callback to call when the input value has been changed.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     * @function onChange
     */
    onChange?: (newValue: any) => void,
    /**
     * dot-notation path in the data structure to the value.
     */
    optionValue?: string,
    /**
     * if orderable, label for the first column of the Grid. In that column a RowUp/RowDown icon will be rendered to be able to order rows.
     */
    orderColumnLabel?: string,
    /**
     * if true, Grid will be orderable.
     */
    orderable?: boolean,
    /**
     * function to determinate if a row needs to be dimmed.
     * @function
     * @param  {*} value of the row
     * @return {boolean}
     */
    rowDimmed?: (rowValue: any) => boolean,
    /**
     * if true the table has a smaller width so it can fit adjacent to the label (if any)
     */
    smallSize?: boolean,
    /**
     * if true, the Add/Edit will use a TabbedForm instead of a Form.
     */
    tabbed?: boolean,
    /**
     * current value of the input.
     * provided automatically when component is rendered inside a [Input](/?path=/docs/core-components-inputs-input) component.
     */
    value?: object | object[] | string,
    /**
     * override Form/TabbedForm props
     */
    formProps?: TableInputAddEditFormProps["formProps"]
}

const styles = (theme: Theme) => createStyles<string, BaseEditableTableProps>({
    root: {
        margin: 16,
        marginTop: 8,
        "& th": {
            textTransform: "uppercase"
        }
    },
    rootSmall: {
        "& th": {
            textTransform: "uppercase"
        },
        width: "550px"
    },
    cardActions: {
        width: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        flexDirection: "column"
    },
    cardActionsWithAddIcon: {
        width: "100%",
        paddingLeft: 0,
        paddingRight: 0,
        flexDirection: "column",
        marginBottom: "-55px",
        position: "relative",
        zIndex: 5
    },
    table: {
        "& .k-grid-content": {
            overflowY: "auto",
        },
        "& table": {
            minWidth: (props) => React.Children.toArray(props.children)
                    .filter((child): child is React.ReactElement => !!child)
                    .reduce(
                        (total, child) =>
                            total + (child && !get(child, "props.hidden") ? get(child, "props.columnProps.width", get(child, "props.width", props.minCellWidth)) : 0),
                        0
                    )
                + (props.orderable ? 96 : 0) + ((!props.noEdit || !props.noDelete) ? 96 : 0)
        }
    },
    tableError: {
        marginLeft: theme.spacing(1)
    },
    tableContainerError: {
        border: "solid 1px " + theme.palette.error.main,
        borderRadius: 4,
        marginTop: -20,
        marginLeft: -4,
        paddingTop: 20,
        paddingLeft: 4,
        width: "calc(100% + 8px)",
        display: "inline-flex"
    },
    orderCell: {
        width: 60,
        paddingRight: 8,
        paddingLeft: "8px !important"
    },
    cellError: {
        border: "solid 1px " + theme.palette.error.main + "!important"
    },
    rowError: {
        borderTop: "solid 1px " + theme.palette.error.main + "!important",
        borderBottom: "solid 1px " + theme.palette.error.main + "!important",
        "&:first-child": {
            borderLeft: "solid 1px " + theme.palette.error.main + "!important",
        },
        "&:last-child": {
            borderRight: "solid 1px " + theme.palette.error.main + "!important",
        }
    },
    dimmedRow: {
        opacity: 0.5,
        backgroundColor: theme.palette.action.disabledBackground + "!important"
    },
    actionButtons: {
        padding: 2
    }
});
const useStyles = makeOverrideableStyles("Table", styles);

export interface EditableTableProps extends BaseEditableTableProps, StyledComponentProps<typeof styles> {}

/**
 * Renders a CardActions and a Grid component wrapped within a div.
 * The CardAction component will render a [TableActionsAndFilters](/?path=/docs/core-components-table-tableactionsandfilters--table-actions-and-filters) component, which is basically an 'Add' button which will render
 * a form when clicked.
 * The data will be rendered on a Grid, with edit/delete actions icons (if noEdit and noDelete props are provided respectively).
 */
export const EditableTable = (props: EditableTableProps) => {
    const {
        addButtonProps,
        addButtonIcon,
        children,
        defaultFormValues,
        deleteButtonProps,
        disabled,
        editButtonProps,
        formChildren,
        formatError,
        formatFormData,
        formatInput,
        formatOutput,
        formValidate,
        id,
        error,
        onChange,
        onBlur,
        labels = {
            addTitle: "cuda.buttons.add",
            addButtonText: "cuda.buttons.add",
            editTitle: "cuda.buttons.edit"
        },
        minimumItems,
        noCreate,
        noDelete,
        noEdit,
        optionValue = "index",
        orderable,
        orderColumnLabel,
        rowDimmed,
        smallSize,
        value = "",
        tabbed,
        formProps
    } = props;
    const classes = useStyles(props);
    const inputValue = value || [];
    const data = (formatInput ? formatInput(inputValue) : inputValue);
    const currentError = (formatError ? formatError(error, inputValue) : error);
    const errorArray = isArray(currentError) && currentError || [];
    const errorString = isString(currentError) && currentError;
    const moveRow = (index: number, movement: number) => {
        const newIndex = index + movement;
        if (newIndex >= 0 && newIndex < data.length) {
            const newData = data.filter((row: any, rowIndex: number) => rowIndex !== index);
            newData.splice(newIndex, 0, data[index]);
            const newValue = formatOutput ? formatOutput(newData, inputValue) : newData;
            onChange && onChange(newValue);
            onBlur && onBlur(newValue);
        }
    };
    const childrenForForm = formChildren || React.Children.map(children, (child) => {
        if (child && child.props.inputComponent) {
            const {inputComponent: InputComponent, inputProps, ...childProps} = child.props;
            return React.createElement(InputComponent, {key: childProps.source, ...childProps, ...(inputProps || {})});
        }
        return null;
    });
    const childArray = React.Children.toArray(children).filter((child): child is React.ReactElement => !!child && !get(child, "props.hidden"));
    const [translate] = useTranslation();

    // TODO can this be simplified at all by using the new EnhancedGridHooks ?
    return (
        <div className={smallSize ? classes.rootSmall : classes.root} id={id ? "table-input-" + id : undefined}>
            <CardActions className={addButtonIcon ? classes.cardActionsWithAddIcon : classes.cardActions} disableSpacing>
                <TableActionsAndFilters
                    actions={!noCreate ? [
                        <TableInputAddEditDialog
                            key="add"
                            {...merge(
                                {
                                    title: labels?.addTitle,
                                    buttonText: labels?.addButtonText,
                                    onSubmit: (newData: any) => {
                                        const newValue = formatOutput ? formatOutput([...data, newData], inputValue) : [...data, newData];
                                        onChange && onChange(newValue);
                                        onBlur && onBlur(newValue);
                                    },
                                    addButtonIcon,
                                    formValidate,
                                    formatFormData,
                                    tabbed,
                                    data: defaultFormValues,
                                    disabled,
                                    formProps
                                },
                                addButtonProps
                            )}
                        >
                            {childrenForForm}
                        </TableInputAddEditDialog>
                    ] : undefined}
                />
            </CardActions>
            <Grid
                data={data}
                className={classNames(classes.table, errorString ? classes.tableContainerError : undefined)}
                rowRender={(row, rowProps) => React.cloneElement(row, {
                    className: classNames(
                        row.props && row.props.className,
                        // @ts-ignore This isn't on GridRowProps, but can be provided by our own props.
                        rowProps.className,
                        rowDimmed && rowDimmed(rowProps.dataItem) && classes.dimmedRow,
                    )
                })}
                cellRender={(cell, {className, columnIndex, dataIndex, dataItem}) => {
                    let child: React.ReactElement;
                    let error;
                    const childIndex = (columnIndex || 0) - (orderable ? 1 : 0);
                    const rowHasError = errorArray && errorArray[dataIndex] && (typeof errorArray[dataIndex] === "string" || !childArray.some(({props: {source}}) => get(errorArray[dataIndex], source)));
                    if (orderable && columnIndex === 0) {
                        child = (
                            <ActionButtonsField left cellClassName={classes.orderCell} label={orderColumnLabel}>
                                <IconButton
                                    size="small"
                                    onClick={() => moveRow(dataIndex, -1)}
                                    disabled={disabled || dataIndex < 1}
                                    className={classes.actionButtons}
                                >
                                    <ArrowUpwardIcon
                                        id="cuda-icon-up"
                                    />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={() => moveRow(dataIndex, 1)}
                                    disabled={disabled || dataIndex >= (data.length - 1)}
                                    className={classes.actionButtons}
                                >
                                    <ArrowDownwardIcon
                                        id="cuda-icon-down"
                                    />
                                </IconButton>
                            </ActionButtonsField>
                        );
                    } else if (childIndex === childArray.length) {
                        child = (
                            <ActionButtonsField>
                                {!noEdit && (
                                    <TableInputAddEditDialog
                                        {...merge(
                                            {
                                                onSubmit: (value: any, initialValue: any) => {
                                                    const newData = data.map((existingItem: any, index: number) => {
                                                        if (get({index, ...existingItem}, optionValue) === get(initialValue, optionValue)) {
                                                            return value;
                                                        }
                                                        return existingItem;
                                                    });
                                                    const newValue = formatOutput ? formatOutput(newData, inputValue) : newData;
                                                    onChange && onChange(newValue);
                                                    onBlur && onBlur(newValue);
                                                },
                                                formValidate,
                                                formatFormData,
                                                tabbed,
                                                initialErrors: errorArray && errorArray[dataIndex],
                                                title: labels?.editTitle || "",
                                                edit: true,
                                                disabled,
                                                formProps,
                                                rowIndex: dataIndex
                                            },
                                            editButtonProps
                                        )}
                                    >
                                        {childrenForForm}
                                    </TableInputAddEditDialog>
                                ) || null}
                                {!noDelete && (
                                    <DeleteDialog
                                        {...merge(
                                            {
                                                onDelete: (params: CrudParams) => {
                                                    const newData = data.filter((value: any, index: number) => get({index, ...value}, optionValue) !== params.id);
                                                    const newValue = formatOutput ? formatOutput(newData, inputValue) : newData;
                                                    onChange && onChange(newValue);
                                                    onBlur && onBlur(newValue);
                                                },
                                                optionValue,
                                                message: labels?.deleteMessage,
                                                title: labels?.deleteTitle,
                                                disabled: disabled || (minimumItems && data && data.length <= minimumItems),
                                            },
                                            deleteButtonProps
                                        )}
                                    />
                                ) || null}
                            </ActionButtonsField>
                        );
                    } else {
                        child = childArray[childIndex];
                        error = get(errorArray, `[${dataIndex}].${get(child, "props.source")}`);
                    }

                    return (
                        <td className={classNames(className, error && classes.cellError, rowHasError && classes.rowError)}>
                            {child && React.cloneElement(child, {
                                data: dataItem,
                                index: dataIndex,
                                total: data.length,
                                disabled
                            }) || null}
                        </td>
                    );
                }}
            >
                <GridNoRecords>
                    {translate(labels?.noDataMessage || "cuda.inputs.table.noDataMessage")}
                </GridNoRecords>
                {orderable ? (
                    <GridColumn
                        field="order"
                        title={orderColumnLabel && translate(orderColumnLabel) || " "}
                        width={96}
                    />
                ) as any /** Need to do 'as any' here as the Kendo table isnt properly typescripted to accept arrays (even though it works fine). */ : null}
                {childArray.map((child, childIndex) => (
                    <GridColumn
                        key={child.props.source || childIndex}
                        field={child.props.source}
                        title={child.props.label && translate(child.props.label) || " "}
                        className={child.props.cellClassName}
                        headerClassName={child.props.cellClassName}
                        width={child.props.width}
                        {...child.props.columnProps}
                    />
                ))}
                {(!noEdit || !noDelete) && (
                    <GridColumn
                        field="actions"
                        title=" "
                        width={96}
                    />
                ) || null}
            </Grid>
            {errorString && (
                <FormHelperText error className={classes.tableError}>
                    {errorString}
                </FormHelperText>
            )}
            {errorArray
                .filter((row) => !!row && typeof row === "string")
                .map((errorText) => (
                    <FormHelperText error className={classes.tableError} key={errorText}>
                        {errorText}
                    </FormHelperText>
                ))}
        </div>
    );
};

EditableTable.defaultProps = {
    minCellWidth: 128
};

export default EditableTable;