import React, {useRef} from "react";

import {
    ActionButtonsField,
    ConnectedTableRefresh,
    CustomField,
    DeleteDialog,
    TablePage,
    TextField,
    useMixpanel,
} from "@cuda-react/core";

import {useTranslation} from "react-i18next";
import apiResources from "../../../apiResources";
import useDemoApi from "../../../hooks/useDemoApi";
import CreateEditButtonDialog from "../../../components/CreateEditButtonDialog";
import {CreateEditGroup} from './components/CreateEditGroup';
import {capitalize} from "lodash";
import {ChipMembersField} from '../components/ChipMembersField';

export const Groups = () => {
    const [translate] = useTranslation();
    const groupsApi = useDemoApi(apiResources.identityGroups, apiResources.demoGroups);
    const usersApi = useDemoApi(apiResources.identityUsers, apiResources.demoUsers);
    const registerAction = useMixpanel('Groups');
    const tableRefreshRef = useRef<ConnectedTableRefresh | null>(null);

    return (
        <TablePage
            authenticated
            pageMode="paginate"
            title="tesseract.identity.pageTitle"
            subtitle="tesseract.identity.groups.title"
            resource={groupsApi}
            noDataMessage="tesseract.identity.groups.noDataMessage"
            tableName="tesseract.identity.groups.tableName"
            refreshRef={tableRefreshRef}
            sortable={false}
            actions={[
                <CreateEditButtonDialog
                    key="create"
                    create
                    component={CreateEditGroup}
                    type="trustedRoot"
                    label="tesseract.identity.groups.create"
                    onSuccess={() => {
                        registerAction("Create");
                        tableRefreshRef.current?.();
                    }}
                />
            ]}
        >
            <TextField
                source="displayName"
                label="tesseract.identity.groups.name"
                filter="text"
            />
            <CustomField
                source="type"
                label="tesseract.identity.groups.type"
                render={(type: string) => capitalize(type)}
                filter="select"
                filterProps={{
                    choices: [{key: "local", name: "Local"}, {key: "external", name: "External"}]
                }}
            />
            <TextField
                source="directory.name"
                label="tesseract.identity.groups.directory"
                defaultText="-"
                filter="selectarray"
                filterProps={{
                    resource: apiResources.directories,
                    optionValue: "id",
                    source: "directoryId",
                }}
            />
            <CustomField
                source="id"
                label="tesseract.identity.groups.members"
                render={(id) => <ChipMembersField resource={usersApi} params={{filter: {groups: id}}}/>}
            />
            <TextField
                source="description"
                label="tesseract.identity.groups.description"
            />
            <ActionButtonsField
                width={142}
                source="id"
                alwaysVisible
            >
                <CreateEditButtonDialog
                    key="edit"
                    component={CreateEditGroup}
                    label="tesseract.identity.groups.cantEditExternalGroups"
                    disabled={({type}) => type === "external"}
                    disabledMessage="tesseract.identity.groups.cantEditExternalGroups"
                    onSuccess={() => {
                        registerAction('Update');
                        tableRefreshRef.current?.();
                    }}
                />
                <DeleteDialog
                    resource={apiResources.identityGroups}
                    title="tesseract.identity.groups.delete.title"
                    disable={({type}) => type === "external"}
                    disabledMessage="tesseract.identity.groups.cantDeleteExternalGroups"
                    optionText="displayName"
                    message="tesseract.identity.groups.delete.body"
                    onSuccess={() => {
                        registerAction('Deleted');
                        tableRefreshRef.current?.();
                    }}
                />

            </ActionButtonsField>
        </TablePage>
    );
};