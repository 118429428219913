import React, {MutableRefObject, useCallback, useMemo} from "react";
import {CrudTypes, MenuButton, useCrudSubscription, usePreview} from "@cuda-react/core";

import {useTranslation} from "react-i18next";
import {UserDirectoryType} from "../UserDirectoriesTypes";
import useDemoApi from "../../../../../hooks/useDemoApi";
import apiResources from "../../../../../apiResources";

const i18nScope = "tesseract.identity.userDirectories";

interface AddUserDirectoryButtonProps {
    onSelect: (type: UserDirectoryType) => void;
    refreshRef?: MutableRefObject<(() => void)>;
}

const DIRECTORIES_TOTAL_LIMIT = 10;

export const AddUserDirectoryButton = ({onSelect, refreshRef}: AddUserDirectoryButtonProps) => {
    const [translate] = useTranslation();

    const identityApi = useDemoApi(apiResources.directories, apiResources.demoDirectories);

    const [{data}, , performFetch] = useCrudSubscription(
        CrudTypes.GET,
        identityApi,
        {
            pagination: {
                page: 1,
                perPage: 1000,
            }
        }
    );

    const isLimited = data?.total >= DIRECTORIES_TOTAL_LIMIT;

    if (refreshRef) {
        refreshRef.current = performFetch;
    }

    const clickHandler = useCallback((type) => () => {
        onSelect(type);
    }, [onSelect]);

    const scimPreview = usePreview('scim');

    const menuItems = useMemo<{label: string; onClick: () => void}[]>(
        () => [
            {
                label: translate(`${i18nScope}.form.type.entraid`),
                onClick: clickHandler('entraid'),
            },
            {
                label: translate(`${i18nScope}.form.type.google`),
                onClick: clickHandler('google'),
            },
            {
                label: translate(`${i18nScope}.form.type.okta`),
                onClick: clickHandler('okta'),
            },
            {
                label: translate(`${i18nScope}.form.type.bcc`),
                onClick: clickHandler('bcc'),
            },
            {
                label: translate(`${i18nScope}.form.type.ldap`),
                onClick: clickHandler('ldap'),
            },
            ...(scimPreview
                ? [
                      {
                          label: translate(`${i18nScope}.form.type.scim`),
                          onClick: clickHandler('scim'),
                      },
                  ]
                : []),
        ],
        [translate, onSelect, scimPreview]
    );

    return (
        <MenuButton
            label="tesseract.identity.userDirectories.createDirectory"
            disabled={isLimited}
            menuItems={menuItems}/>
    );
};