import React, {useCallback, useMemo, useState} from 'react';
import {
    CrudTypes,
    DialogBody,
    MenuButton,
    NotificationDisplayType,
    NotificationEventType,
    NotificationLevel,
    useCrudFetch,
    useMixpanel,
    usePostNotification,
} from '@cuda-react/core';
import apiResources from '../../../../../apiResources';
import {useTranslation} from 'react-i18next';
import {CreatedUserDirectory} from '../UserDirectoriesTypes';
import {Box, Dialog} from '@barracuda-internal/bds-core';
import {TestConnectivityDialog} from './TestConnectivityDialog';
import {SCIMInfo} from './UserDirectoryForm/components/SCIMInfo';

const i18nScope = 'tesseract.identity.userDirectories';

export const UserDirectoryMenu = ({data}: {data?: CreatedUserDirectory}) => {
    const registerAction = useMixpanel('User Directories', false);
    const postNotification = usePostNotification();
    const [, , performSync] = useCrudFetch(
        CrudTypes.CREATE,
        apiResources.directoriesSync
    );
    const [showTestConnectivity, setShowTestConnectivity] = useState(false);
    const [showSCIMSetup, setShowSCIMSetup] = useState(false);

    const [translate] = useTranslation();

    const handleSync = useCallback(() => {
        if (!data) {
            return;
        }

        performSync({id: data?.id});
        postNotification({
            event: NotificationEventType.SHOW,
            display: NotificationDisplayType.TOAST,
            params: {
                content: translate(`${i18nScope}.syncedNotification`),
                level: NotificationLevel.INFO,
                duration: 5000,
            },
        });
        registerAction('manually synced');
    }, [data, performSync, postNotification, registerAction, translate]);

    const menuItems = useMemo(() => {
        const items = [];

        if (data?.type !== 'scim') {
            items.push({
                label: 'tesseract.identity.userDirectories.sync',
                onClick: handleSync,
                disabled: data?.connectionStatus === 'needs_consent',
                disabledTooltipText:
                    'tesseract.identity.userDirectories.syncDisabled',
            });
        }
        if (data?.type === 'ldap') {
            items.push({
                label: 'tesseract.identity.userDirectories.testConnectivity',
                onClick: () => setShowTestConnectivity(true),
            });
        }
        if (data?.type === 'scim') {
            items.push({
                label: 'tesseract.identity.userDirectories.setupSCIM',
                onClick: () => setShowSCIMSetup(true),
            });
        }
        return items;
    }, [data, handleSync]);

    return (
        <>
            <MenuButton
                anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
                transformOrigin={{horizontal: 'left', vertical: 'top'}}
                menuItems={menuItems}
                iconButton
            />
            {data?.type === 'ldap' && (
                <Dialog open={showTestConnectivity}>
                    {showTestConnectivity && data && (
                        <TestConnectivityDialog
                            directory={data}
                            onClose={() => setShowTestConnectivity(false)}
                        />
                    )}
                </Dialog>
            )}
            {data?.type === 'scim' && data?.id ? (
                <Dialog open={showSCIMSetup}>
                    <DialogBody
                        title='tesseract.identity.userDirectories.setupSCIM'
                        onClose={() => setShowSCIMSetup(false)}
                    >
                        <Box p={3}>
                            <SCIMInfo id={data?.id} create={false} />
                        </Box>
                    </DialogBody>
                </Dialog>
            ) : null}
        </>
    );
};