import React from 'react';
import {UserDirectorySCIM} from '../../UserDirectoriesTypes';
import {
    ConnectedWizard,
    ResultStep,
    SubmitStep,
    TextInput,
} from '@cuda-react/core';
import apiResources from '../../../../../../apiResources';
import {SCIMInfo} from './components/SCIMInfo';

type UserDirectorySCIMFormProps = {
    directory: UserDirectorySCIM;
    create: boolean;
    id?: string;
    onClose: (refresh: boolean) => void;
};

const i18nScope = 'tesseract.identity.userDirectories.form';

const ShowSCIMData = ({result, create}: {result?: any; create: boolean}) => (
    <SCIMInfo id={result?.data?.id} create={create} />
);

export const UserDirectorySCIMForm: React.FC<UserDirectorySCIMFormProps> = ({
    onClose,
    create,
    directory,
    id,
}: UserDirectorySCIMFormProps) => (
    <ConnectedWizard
        resource={apiResources.directories}
        onCancel={() => onClose(true)}
        initialValues={directory}
        update={!create}
        submitParams={create ? undefined : {id}}
    >
        <SubmitStep
            label={`${i18nScope}.scim.steps.userDirectory`}
            primaryLabel={`${i18nScope}.scim.steps.${
                create ? 'submitAdd' : 'submitEdit'
            }`}
        >
            <TextInput
                source='displayName'
                label={`${i18nScope}.displayName`}
                isRequired
            />
        </SubmitStep>
        <ResultStep
            label={`${i18nScope}.scim.steps.info`}
            onClose={() => onClose(true)}
        >
            <ShowSCIMData create={create} />
        </ResultStep>
    </ConnectedWizard>
);