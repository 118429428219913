import React from 'react';
import CopyToClipboardButton from '../../../../../../../components/CopyToClipboardButton';
import {Hide, Show} from '@barracuda-internal/bds-core/dist/Icons/Core';
import {InputAdornment, Typography} from '@mui/material';
import {IconButton} from '@barracuda-internal/bds-core';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    token: {
        wordBreak: 'break-all',
        flex: 1,
    },
}));

interface SCIMTokenField {
    token: string;
}

export const SCIMTokenField = ({token}: SCIMTokenField) => {
    const classes = useStyles();
    const [visible, setVisible] = React.useState(false);

    return (
        <div
            className={classes.root}
        >
            <Typography
                variant='body1'
                className={classes.token}
            >
                {visible ? token : '•'.repeat(token?.length || 0)}
            </Typography>
            <InputAdornment position='end'>
                <IconButton size='small' onClick={() => setVisible(!visible)}>
                    {visible ? <Hide /> : <Show />}
                </IconButton>
            </InputAdornment>
            <CopyToClipboardButton value={token || ''} iconOnly />
        </div>
    );
};